import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderPage } from "../components/structure"
export default function Quote() {
  return (
    <Layout>
      <SEO title="Free Quote" />
      <HeaderPage
        color={props => props.theme.color.complimentry}
        bgColor={props => props.theme.color.bgFirst}
        title="We would loved to chat with you first"
        catchy="Free Quote"
      />
    </Layout>
  )
}
